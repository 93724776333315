@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-body {
  background-color: #f3f4f6;
}

.scooter-editor {
  height: 78vh !important;
}

.d-none {
  display: none;
}

/* audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
background-color: #F3EED9;
} */

/* if  screen less than 640px */
@media (max-width: 640px) {
  .d-sm-none {
    display: none;
  }

  .sm-fixed {
    position: fixed;
  }

  .sm-left-0 {
    left: 0;
  }

  .sm-right-0 {
    right: 0;
  }

  .sm-top-0 {
    top: 0;
  }

  .sm-w-100-screen {
    width: 100vw;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.scrollbar-custom {
  scrollbar-width: none;
  scrollbar-color: #000000 #b0b0b0;
}

/* Chrome, Edge, and Safari */
.scrollbar-custom::-webkit-scrollbar {
  width: 6px;
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: #b0b0b0;
  border-radius: 8px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: #696969;
  border-radius: 8px;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background-color: #000;
}

/* hide scrollbar */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: #1e1e1e;
}

.prose {
  max-width: 80%;
}
.fill-666666 > path {
  fill: #666666;
}

.fill-1e1e1e > path {
  fill: #1e1e1e;
}

.user-select-none {
  user-select: none;
  -moz-user-select: none;
}
